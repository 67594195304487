import React, { useState } from 'react';
import API from '../../api';
import Error from '../Form/errors';
import { toast } from 'react-toastify';
import { useIntl, navigate } from 'gatsby-plugin-react-intl';

import {
  useStripe,
  useElements,
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';

const CheckoutCardForm = ({
  user,
  total,
  errors,
  eventId,
  eventDateId,
  eventTicketObj,
  enableProtection,
  makeModalInactive,
}) => {
  const intl = useIntl();
  const stripe = useStripe();
  const elements = useElements();
  const [loadingClass, setLoadingClass] = useState('');

  const CARD_OPTIONS = {
    iconStyle: 'solid',
    showIcon: true,
    style: {
      base: {
        iconColor: '#000',
        color: '#424770',
        fontWeight: 500,
        letterSpacing: '0.025em',
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        '::placeholder': { color: '#dedede' },
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': { color: '#fce883' },
        padding: '10px',
      },
    },
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setLoadingClass('is-loading');
    await stripe
      .createToken(elements.getElement(CardNumberElement))
      .then((res) => {
        if (res && res.token && res.token.id) {
          const { first_name, last_name, email, user_id } = user;
          API.post('payments', {
            stripe_token: res.token.id,
            email,
            first_name,
            last_name,
            price: total,
            user_id,
            event_id: eventId,
            enable_protection: enableProtection,
            tickets_overview: eventTicketObj.map((ticket) => ({
              category_id: ticket.id,
              count: ticket.count,
              seat: ticket.seat || 0,
              name: ticket.name,
              price: ticket.price,
            })),
            event_date_id: eventDateId,
          })
            .then((res) => {
              toast.success('payment finished');
              makeModalInactive();
              setLoadingClass('');
              localStorage.removeItem('shopping_cart');
              navigate(`/successfully-payment#${res.data?.id}`);
            })
            .catch((error) => {
              setLoadingClass('');
              const couponErrorMessage =
                error.response &&
                error.response.data.message.includes('No such coupon:');
              if (couponErrorMessage) {
                toast.error(
                  'There is no such coupon, please write a valid one'
                );
              } else {
                toast.error('register went wrong');
              }
            });
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="content profile-content-wrapper">
        <div className="column is-full">
          <div className="field">
            <p className="control">
              <label className="label">
                {intl.formatMessage({
                  id: 'card_number',
                  defaultMessage: 'Card Number',
                })}
              </label>
              <CardNumberElement
                options={CARD_OPTIONS}
                className="input is-large"
                required
              />
              <Error errors={errors} field={'card_number'} />
            </p>
          </div>
        </div>

        <div className="column is-full">
          <div className="field">
            <p className="control">
              <label className="label">
                {intl.formatMessage({
                  id: 'expiry_date',
                  defaultMessage: 'Expiry Date',
                })}
              </label>
              <CardExpiryElement
                options={CARD_OPTIONS}
                className="input is-large"
                required
              />
              <Error errors={errors} field={'expire_date'} />
            </p>
          </div>
        </div>

        <div className="column is-full">
          <div className="field">
            <p className="control">
              <label className="label">
                {intl.formatMessage({
                  id: 'cvc',
                  defaultMessage: 'CVC',
                })}
              </label>
              <CardCvcElement
                options={CARD_OPTIONS}
                className="input is-large"
                required
              />
              <Error errors={errors} field={'cvc'} />
            </p>
          </div>
        </div>
        <div className="column is-full">
          <div className="field">
            <button
              type="submit"
              className={`button full-width ${loadingClass}`}
            >
              {intl.formatMessage({
                id: 'pay',
                defaultMessage: 'Pay',
              })}
            </button>
          </div>
        </div>
        <div className="columns last-items">
          <div className="column is-full">
            <div className="flex-item-center">
              <p
                className="action-items red-item"
                onClick={() => makeModalInactive()}
              >
                {intl.formatMessage({
                  id: 'Cancel',
                  defaultMessage: 'Cancel',
                })}
              </p>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CheckoutCardForm;
