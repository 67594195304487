import React from "react";
//import PayPalButtonPayment from "./PayPalButtonPayment"
import MasterCardIcon from "../../assets/images/master_card_icon.png";
import VisaIcon from "../../assets/images/visa_icon.png";
//import PaypalIcon from "../../assets/images/paypal_icon.png"
import { useIntl } from "gatsby-plugin-react-intl";
import { Link } from "gatsby";

const PaymentSection = props => {
  const intl = useIntl();

  return (
    <div className="checkout-wrapper">
      <div className="control">
        <div className="payment-type-wrapper">
          <label className="radio">
            <input
              type="radio"
              name="payment_type"
              value="transfer"
              onChange={e => props.handleOnChange(e, props.setPaymentMethod)}
              disabled
            />
            {intl.formatMessage({
              id: "transfer_payment",
              defaultMessage: "Transfer (prepayment)",
            })}
          </label>
          <span className="payment-type-desc">
            <span className="arrow-up"></span>
            <p>
              Transfer directly to our bank account. Please use the order number
              as intended purpose. Your order will be shipped only after receipt
              of money on our account.
            </p>
          </span>
        </div>
        <div className="level payment-type-wrapper">
          <label className="radio level-left">
            <input
              type="radio"
              name="payment_type"
              value="stripe"
              onChange={e => props.handleOnChange(e, props.setPaymentMethod)}
              required
            />
            {intl.formatMessage({
              id: "credit_card",
              defaultMessage: "Credit card",
            })}
          </label>
          <span className="level-right">
            <img src={VisaIcon} alt="Visa" />
            <img src={MasterCardIcon} alt="Master" />
          </span>
        </div>
        {/* <div className="level payment-type-wrapper">
                    <label className="radio level-left">
                        <input type="radio" name="payment_type" value="paypal" onChange={(e) => props.handleOnChange(e, props.setPaymentMethod)} required />
                    PayPal
                </label>


                    < span className="level-right">
                        <img src={PaypalIcon} alt="paypal" />
                        {(props.total !== 0) && (
                            <PayPalButtonPayment disabled={true} total={props.total} currency={'EUR'}
                                style={{
                                    size: 'responsive',
                                    shape: 'rect',
                                    color: 'blue',
                                    layout: 'horizontal',
                                    label: 'paypal',
                                }}
                                language="de_DE" />
                        )}
                    </span>
                </div> */}
        <div className="no-form-row-border payment-type-wrapper">
          <label className="checkbox">
            <input type="checkbox" name="accept" required />
            {intl.formatMessage({
              id: "read_term",
              defaultMessage: "I have read",
            })}{" "}
            <Link to={`/${intl.locale}/terms-of-use`}>
              {intl.formatMessage({
                id: "terms",
                defaultMessage: "terms and conditions",
              })}
            </Link>{" "}
            {intl.formatMessage({
              id: "accept_them",
              defaultMessage: "and accept them. *",
            })}
          </label>
        </div>
        <button
          className="button is-fullwidth"
          disabled={props.total === 0}
          type="submit"
        >
          {intl.formatMessage({
            id: "finish_payment",
            defaultMessage: "Finish payment",
          })}
        </button>
      </div>
    </div>
  );
};

export default PaymentSection;
