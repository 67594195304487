import React, { useEffect, useMemo, useState } from 'react';
import API from '../api';
import Layout from '../layouts/Layout';
import queryString from 'query-string';
import Seo from '../components/Seo/Seo';
import UserForm from '../components/CheckoutCard/UserForm';
import CardDetails from '../components/CheckoutCard/CardDetails';
import PaymentSection from '../components/CheckoutCard/PaymentSection';
import CreditCardPayment from '../components/CheckoutCard/CreditCardPayment';
import {
  get as _get,
  size as _size,
  reverse as _reverse,
  includes as _includes,
} from 'lodash';
import dayjs from 'dayjs';
const isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);

const PaymentSettings = () => {
  const [url, setUrl] = useState(null);
  const [event, setEvent] = useState([]);
  const [eventTickets, setEventTickets] = useState([]);
  const [enableProtection, setEnableProtection] = useState(false);
  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    user_id: null,
  });
  const [address, setAddress] = useState({
    city: '',
    country: '',
    postal_code: '',
    street: '',
    user_id: '',
    address_id: '',
  });
  const [userErrors, setUserErrors] = useState('');
  const [eventDateObj, setEventDateObj] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState({});
  const [ticketSelected, setTicketSelected] = useState([]);
  const [activatePaymentModal, setActivatePaymentModal] = useState(false);
  const [selectedTicketCategories, setSelectedTicketCategories] = useState([]);

  const getUrlParams = () => {
    setUrl(queryString.parse(window.location.search));
  };

  const reOrganizeSelectedTickets = () => {
    if (_size(selectedTicketCategories) > 0) {
      const ticketsSelected = selectedTicketCategories.replace(/[[\]']+/g, '');
      ticketsSelected.split(',').map((ticket) => {
        const element = ticket.split(':');
        setTicketSelected((prevValue) => ({
          ...prevValue,
          [element[0]]: Number(element[1]),
        }));
        return element;
      });
    }
  };

  const getEvent = (eventId) => {
    API.get(`events/${eventId}`)
      .then((res) => {
        setEvent(res.data);
      })
      .catch((err) => console.error(err));
  };

  const getEventTickets = (eventId) => {
    API.get(`events/${eventId}/ticket-categories`)
      .then((res) => {
        setEventTickets(_reverse(res.data));
      })
      .catch((err) => console.error(err));
  };

  const eventTicketOb = eventTickets
    .filter((eventTicket) => {
      return _includes(selectedTicketCategories, eventTicket.id);
    })
    .map((ticket) => {
      ticket.count = ticketSelected[ticket.id];
      if (ticket.ticket_category_custom_price) {
        ticket.ticket_category_custom_price.map((customPrice) => {
          const startDate = dayjs(customPrice.from);
          const endDate = dayjs(customPrice.to);
          const isOnDateRange = dayjs(eventDateObj.datetime).isBetween(startDate, endDate, null, '[]');
          if (
            ticket.id === customPrice.ticket_category_id && isOnDateRange
          ) {
            ticket.price = customPrice.price;
          }
          return customPrice;
        });
      }
      return ticket;
    });

  const getEventDateObj = (eventId, eventDate) => {
    const eventDateParam = eventDate.replaceAll('/', '-');
    API.get(`events/${eventId}/dates/${eventDateParam}`)
      .then((res) => {
        setEventDateObj(res.data);
      })
      .catch((err) => console.error(err));
  };


  const total = useMemo(() => {
    let totalPrice = 0;
    eventTicketOb.map((ticket) => {
      return (totalPrice += ticket.count * ticket.price);
    });
    return totalPrice;
  }, [eventTicketOb]);

  const handleOnChange = (e, setter) => {
    const { name, value } = e.target;
    setter((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const onSubmitForm = (ev) => {
    ev.preventDefault();

    if (user.id) {
      API.put(`users/${user.id}`, user)
        .then((res) => {
          setAddress((prevValue) => ({
            ...prevValue,
            user_id: res.data.id,
          }));
          setUser((prevValue) => ({
            ...prevValue,
            user_id: res.data.id,
          }));
          if (address?.address_id) {
            API.put(`users/${user.id}/addresses/${address.id}`, address);
          } else {
            API.post(`users/${res.data.id}/addresses`, address);
          }
          if (paymentMethod.payment_type === 'stripe') {
            setActivatePaymentModal(true);
          }
        })
        .catch((err) => setUserErrors(err.data));
    } else {
      API.post('users', user)
        .then((res) => {
          setAddress((prevValue) => ({
            ...prevValue,
            user_id: res.data.id,
          }));
          setUser((prevValue) => ({
            ...prevValue,
            user_id: res.data.id,
          }));
          API.post(`users/${res.data.id}/addresses`, address).catch((err) =>
            console.log(err)
          );
          if (paymentMethod.payment_type === 'stripe') {
            setActivatePaymentModal(true);
          }
        })
        .catch((err) => setUserErrors(err.data));
    }
  };

  useEffect(() => {
    if (url) {
      const eventId = _get(url, 'eventId', null);
      const eventDate = _get(url, 'eventDate', null);
      setSelectedTicketCategories(_get(url, 'ticketIds', []));
      getEvent(eventId);
      getEventTickets(eventId);
      getEventDateObj(eventId, eventDate);
    }
  }, [url]);

  useEffect(() => {
    getUrlParams();
  }, []);

  useEffect(() => {
    if (selectedTicketCategories) {
      reOrganizeSelectedTickets();
    }
  }, [selectedTicketCategories]);

  const getUser = (userId) => {
    return API.get(`users/${userId}`);
  };

  const getUserAddress = (userId) => {
    return API.get(`users/${userId}/addresses`);
  };

  useEffect(() => {
    let mounted = true;
    if (localStorage.getItem('user')) {
      const localStgUser = JSON.parse(localStorage.getItem('user'));
      getUser(localStgUser.id).then((loggedInUser) => {
        if (mounted) {
          setUser({ ...loggedInUser.data, user_id: loggedInUser.data.id });
          getUserAddress(localStgUser.id).then((loggedInUsrAddress) => {
            setAddress({
              ...loggedInUsrAddress.data,
              postal_code: loggedInUsrAddress.data.zip,
              street: loggedInUsrAddress.data.address,
              address_id: loggedInUsrAddress.data.id,
            });
          });
        }
      });
      return () => (mounted = false);
    }
  }, []);

  return (
    <Layout section={'payment'}>
      <section className="section homepage-content">
        <div className="container">
          <>
            <div className="columns">
              <div className="column">
                <div className="content profile-content-wrapper">
                  <form className="columns" onSubmit={onSubmitForm}>
                    <UserForm
                      handleOnChange={handleOnChange}
                      user={user}
                      address={address}
                      setUser={setUser}
                      setAddress={setAddress}
                      errors={userErrors}
                    />

                    <div className="column is-4 payment-sidebar">
                      <CardDetails
                        event={event}
                        total={total}
                        eventTicketOb={eventTicketOb}
                        setEnableProtection={setEnableProtection}
                      />
                      <PaymentSection
                        total={total}
                        handleOnChange={handleOnChange}
                        setPaymentMethod={setPaymentMethod}
                      />
                    </div>
                  </form>
                  {activatePaymentModal && (
                    <CreditCardPayment
                      user={user}
                      eventId={event?.id}
                      active={activatePaymentModal}
                      eventTicketObj={eventTicketOb}
                      enableProtection={enableProtection}
                      total={enableProtection ? total + total / 10 : total}
                      eventDateId={_get(eventDateObj, 'id', 0)}
                      hidePaymentModal={() => setActivatePaymentModal(false)}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        </div>
      </section>
    </Layout>
  );
};

export default PaymentSettings;
export const Head = ({ location }) => (
  <Seo
    pathname={location.pathname}
  />
);
