import React, { useState, useEffect } from 'react';
import CheckoutCardForm from './CheckoutCardForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useIntl } from 'gatsby-plugin-react-intl';
import Modal from '../Modal/Modal';

const stripePromise = loadStripe(
  `${process.env.GATSBY_STRIPE_PUBLISHABLE_KEY}`
);

const CreditCardPayment = ({
  user,
  total,
  active,
  eventId,
  eventDateId,
  eventTicketObj,
  hidePaymentModal,
  enableProtection,
}) => {
  const intl = useIntl();
  const [errors, setErrors] = useState('');
  const [modalState, setModalState] = useState(false);

  useEffect(() => {
    setModalState(active);
  }, [active]);

  return (
    <Elements stripe={stripePromise}>
      <Modal
        modalState={modalState}
        title={intl.formatMessage({
          id: 'payment',
          defaultMessage: 'Payment',
        })}
        closeCB={() => {
          setErrors('');
          setModalState(false);
          hidePaymentModal();
        }}
        body={
          <CheckoutCardForm
            user={user}
            total={total}
            errors={errors}
            onSubmitCb={{}}
            eventId={eventId}
            handleOnChange={{}}
            modalState={modalState}
            eventDateId={eventDateId}
            eventTicketObj={eventTicketObj}
            enableProtection={enableProtection}
            makeModalInactive={() => setModalState(false)}
          />
        }
      />
    </Elements>
  );
};

export default CreditCardPayment;
