import React from "react";
import { useIntl } from "gatsby-plugin-react-intl";
import Error from "../Form/errors";

const UserForm = props => {
  const intl = useIntl();

  return (
    <div className="column is-7">
      <h3>{intl.formatMessage({ id: "billing_address" })}</h3>
      <div className="columns">
        <div className="column is-half">
          <div className="field">
            <label className="label">
              {intl.formatMessage({ id: "first_name" })} *
            </label>
            <p className="control">
              <input
                className="input name"
                name="first_name"
                value={props.user.first_name}
                type="text"
                onChange={e => props.handleOnChange(e, props.setUser)}
                required
              />
              <Error errors={props.errors} field={"first_name"} />
            </p>
          </div>
        </div>
        <div className="column is-half">
          <div className="field">
            <label className="label">
              {intl.formatMessage({ id: "last_name" })} *
            </label>
            <p className="control">
              <input
                className="input name"
                name="last_name"
                value={props.user.last_name}
                type="text"
                onChange={e => props.handleOnChange(e, props.setUser)}
                required
              />
              <Error errors={props.errors} field={"last_name"} />
            </p>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <div className="field">
            <label className="label">
              {intl.formatMessage({ id: "email" })} *
            </label>
            <p className="control">
              <input
                className="input name"
                name="email"
                value={props.user.email}
                type="email"
                onChange={e => props.handleOnChange(e, props.setUser)}
                required
              />
              <Error errors={props.errors} field={"email"} />
            </p>
            {/* <p className="control">
                              <div
                                className="dropdown is-hoverable"
                                style={{ width: "100%" }}
                              >
                                <div
                                  className="dropdown-trigger"
                                  style={{ width: "100%" }}
                                >
                                  <button
                                    className="button country-list"
                                    aria-haspopup="true"
                                    aria-controls="dropdown-menu4"
                                  >
                                    <span></span>
                                    <span className="icon is-small">
                                      <img
                                        src={DropDownDarkIcon}
                                        alt="Dropdown icon"
                                      />
                                    </span>
                                  </button>
                                </div>
                                <div className="dropdown-menu">
                                  <div className="dropdown-content">
                                    <a className="dropdown-item"> Vienna 2 </a>
                                    <a className="dropdown-item"> Vienna 3 </a>
                                  </div>
                                </div>
                              </div>
                            </p> */}
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <div className="field">
            <label className="label">
              {intl.formatMessage({ id: "street" })} *
            </label>
            <p className="control">
              <input
                className="input name"
                name="street"
                value={props.address.street}
                type="text"
                onChange={e => props.handleOnChange(e, props.setAddress)}
                required
              />
              <Error errors={props.address.errors} field={"street"} />
            </p>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column is-half">
          <div className="field">
            <label className="label">
              {intl.formatMessage({ id: "postal_code" })} *
            </label>
            <p className="control">
              <input
                className="input name"
                name="postal_code"
                value={props.address.postal_code}
                type="text"
                onChange={e => props.handleOnChange(e, props.setAddress)}
                required
              />
            </p>
          </div>
        </div>
        <div className="column is-half">
          <div className="field">
            <label className="label">
              {intl.formatMessage({ id: "city" })} *
            </label>
            <p className="control">
              <input
                className="input name"
                name="city"
                value={props.address.city}
                type="text"
                onChange={e => props.handleOnChange(e, props.setAddress)}
                required
              />
            </p>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <div className="field">
            <label className="label">
              {intl.formatMessage({ id: "country" })} *
            </label>
            <p className="control">
              <input
                className="input name"
                name="country"
                value={props.address.country}
                type="text"
                onChange={e => props.handleOnChange(e, props.setAddress)}
                required
              />
            </p>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <div className="field">
            <label className="label">
              {intl.formatMessage({ id: "phone" })} *
            </label>
            <p className="control">
              <input
                className="input name"
                name="phone"
                value={props.user.phone}
                type="text"
                onChange={e => props.handleOnChange(e, props.setUser)}
                required
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserForm;
