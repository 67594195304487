import React, { useEffect, useState } from "react";
import { useIntl, navigate } from "gatsby-plugin-react-intl";
import { size as _size } from "lodash";
import CloseIcon from "../../assets/images/close.svg";

const CardDetails = props => {
  const intl = useIntl();
  const [total, setTotal] = useState(0);

  const removeSelectedTicket = () => {
    localStorage.removeItem("shopping_cart");
    navigate(`/`);
  };

  // const addCancelationProtection = e => {
  //   if (e.target.checked) {
  //     setTotal(total + total / 10);
  //     props.setEnableProtection(true);
  //   } else {
  //     setTotal(props.total);
  //     props.setEnableProtection(false);
  //   }
  // };

  const isDeLang = () => intl.locale === 'de';
  const eventName = isDeLang() && props.event.name_de !== null ? props.event.name_de : props.event.name;

  useEffect(() => {
    setTotal(props?.total);
  }, [props?.total]);

  return (
    <div className="cart-details">
      <h4>{intl.formatMessage({ id: "your_cart" })}</h4>
      <div className="pricing-item">
        <h5>{eventName}</h5>
        {_size(props.eventTicketOb) ? (
          <>
            {props.eventTicketOb.map(eventTicket => {
              return (
                <p key={eventTicket.id}>
                  {eventTicket.name} -{" "}
                  <span>{eventTicket.count * eventTicket.price} € </span>(
                  {eventTicket.count}
                  {eventTicket.count === 1
                    ? intl.formatMessage({ id: "ticket" })
                    : intl.formatMessage({ id: "tickets" })}
                  )
                </p>
              );
            })}
            <button
              className="clear-price"
              onClick={() => removeSelectedTicket()}
            >
              <img src={CloseIcon} alt="close" />
            </button>
          </>
        ) : (
          <p>{intl.formatMessage({ id: "no_tickets_available" })}</p>
        )}
      </div>
      {props.total > 0 ? (
        <>
          <div className="level subtotal-wrapper">
            <div className="level-left">
              <span className="subtotal-label">
                {intl.formatMessage({ id: "sub_total" })}
              </span>
            </div>
            <div className="level-right">
              <span className="subtotal-price">€ {props.total}</span>
            </div>
          </div>
          {/* <div className="level subtotal-wrapper">
            <div className="level-left">
              <span
                className="subtotal-label has-tooltip-multiline"
                data-tooltip={intl.formatMessage({
                  id: "cancel_protection_info",
                  defaultMessage:
                    "Unserer Stornoschutz kann hinzugefügt werden und schützt Sie für den Fall, dass Sie einen Kauf stornieren möchten, da Sie zum Beispiel eine Veranstaltung nicht besuchen können. Der Preis des Stornoschutzes berechnet sich vom Warenkorb und wird unterhalb des Gesamtbetrages ausgewiesen.",
                })}
              >
                <label className="checkbox">
                  <input
                    type="checkbox"
                    name="cancelation_process"
                    onChange={e => addCancelationProtection(e)}
                  />
                  {intl.formatMessage({
                    id: "add_cancellation_Protection",
                    defaultMessage: "Add Cancellation Protection",
                  })}
                </label>
              </span>
            </div>
            <div className="level-right">
              <span className="subtotal-price">
                € {props?.total > 0 ? props.total / 10 : props?.total}
              </span>
            </div>
          </div> */}
          <div className="level total-wrapper">
            <div className="level-left">
              <span className="total-label">
                {intl.formatMessage({ id: "total" })}
              </span>
            </div>
            <div className="level-right">
              <span className="total-price">€ {total}</span>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default CardDetails;
